import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

declare var $: any;

interface PopupResponse {
	habilitar_modal: boolean;
	imagem_modal: {
		ID: number;
		id: number;
		title: string;
		url: string;
		filesize: number;
		width?: number;
		height?: number;
	};
	link_modal: {
		title: string;
		url: string;
		target: string;
	};
	tempo_para_o_fechamento_modal: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	habilitarModal: boolean = false;
	imagemModalUrl: string = '';
	imagemModalTitle: string = '';
	linkModalUrl: string = '';
	linkModalTitle: string = '';

	constructor(
		private router: Router,
		private http: HttpClient
	) { }

	ngOnInit() {

		this.fetchPopupData();

	}

  	fetchPopupData() {
		const endpoint = 'https://www.paulistano.org.br/wp-json/api/v1/popup';
	
		this.http.get<PopupResponse>(endpoint).subscribe({
			next: (response) => {
				const body = response;

				if (body.habilitar_modal) {
					const tempo = parseInt(body.tempo_para_o_fechamento_modal, 10) * 1000;

					this.imagemModalUrl = body.imagem_modal.url;
					this.imagemModalTitle = body.imagem_modal.title;
					this.linkModalUrl = body.link_modal.url;
					this.linkModalTitle = body.link_modal.title;
			

					setTimeout(() => {
						$('#modalFraude').modal('show');

						setTimeout(() => {
							$('#modalFraude').modal('hide');
						}, tempo);

					}, 0);
				}

			},
			error: (error) => {
				console.error('Error fetching popup data:', error);
			}
		});
  }
  

  	logout() {
		localStorage.setItem('isLogout', '1');
		localStorage.removeItem('token');
		localStorage.removeItem('localSlips');
		localStorage.removeItem('localSlipsVigente');
		localStorage.removeItem('localSlipsDetails');
		localStorage.removeItem('localSlipsSub');
		this.router.navigate(['/']);
  	}

}
