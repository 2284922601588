import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SecureStorage } from 'src/app/utils/secure-storage';

@Component({
  selector: 'app-form-adesao-boleto-online',
  templateUrl: './form-adesao-boleto-online.component.html',
  styleUrls: ['./form-adesao-boleto-online.component.css'],
})

export class FormAdesaoBoletoOnlineComponent implements OnInit {
  public dataUser: any;
  public infos: Array<any> = [];
  public totalNotRead = 0;
  public loading: any;
  public adesaoForm: FormGroup;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private httpService: HttpService,
    public loaderService:  LoaderService,
  ) {
    this.dataUser = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }


  ngOnInit() {

    this.adesaoForm = this.fb.group({
      'usu_id': [this.dataUser.usu_id_pessoa, []],
      'usu_email_cobranca': [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'email_confirmation': [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'usu_flag_aceite_boleto': [1, []],
    });

    window.scrollTo(0, 0);
    this.dataUser = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }

  onFormSubmit(form: NgForm) {



    if(this.adesaoForm.value.usu_email_cobranca != this.adesaoForm.value.email_confirmation) {
      this.loaderService.openModalAlert('Sucesso', 'Os e-mails não são iguais.', 'error');
      return false;
    }

    this.adesaoForm.value.usu_flag_aceite_boleto = 1;
    this.httpService.post('modules/dashboard/services', 'usuarios/'+this.dataUser.usu_id+'/aceite-boleto', this.adesaoForm.value)
      .subscribe(response => {
        this.loaderService.openModalAlert('Sucesso', 'Solicitação enviada com sucesso.', 'success');
        this.loadUser();
      }, err => {
      });

  }

  onFormSubmitCancel() {


    this.adesaoForm.value.usu_flag_aceite_boleto = 0;
    this.httpService.post('modules/dashboard/services', 'usuarios/'+this.dataUser.usu_id+'/aceite-boleto', this.adesaoForm.value)
      .subscribe(response => {
        this.loaderService.openModalAlert('Sucesso', 'Solicitação enviada com sucesso.', 'success');
        this.loadUser();
      }, err => {
      });

  }


  loadUser() {
    this.httpService.get('getInfoUser', 'usuarios/myaccount')
      .subscribe(response => {
        SecureStorage.setItem('dataUser', response);
        this.dataUser = response;

      }, err => {
        alert('Não foi possível realizar o login.');
      });
  }

  formatDate(date, type) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    if (type == 'br') {
      return diaF + "-" + mesF + "-" + anoF;
    } else {
      return anoF + '-' + mesF + '-' + diaF;
    }
  }

}
