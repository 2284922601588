import { Component, OnInit } from "@angular/core";
import { UtilService } from "src/app/services/util.service";
import { HttpService } from "src/app/services/http.service";
import { SecureStorage } from "src/app/utils/secure-storage";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})

export class HomeComponent implements OnInit {

  public dataUser: any;
  qtd_academia: number = 0;
  qtd_pessoas: number = 0;
  qtd_veiculos: number = 0;
  last_consulta_socio_meter: string = "";


  constructor(
    private utilService: UtilService,
    private httpService: HttpService,
  ) {
    this.dataUser = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
    console.log('dataUser', this.dataUser)
  }

  ngOnInit() {
    if (localStorage.getItem('isLogin')) {
      localStorage.removeItem('isLogin');
      this.refresh();
    }

    this.httpService.get("catraca", "sociometer/catraca").subscribe((quantidade) => {
      const data = new Date();
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      const hora = String(data.getHours()).padStart(2, '0');
      const minutos = String(data.getMinutes()).padStart(2, '0');

      this.last_consulta_socio_meter = dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos;
      this.qtd_academia = quantidade.quantidade;
    }, () => { });

    this.httpService.get("pessoas", "sociometer/pessoas").subscribe((quantidade) => {
      this.qtd_pessoas = quantidade.quantidade;
    }, () => { });

    this.httpService.get("veiculos", "sociometer/veiculos").subscribe((quantidade) => {
      this.qtd_veiculos = quantidade.quantidade;
    }, () => { });

    this.utilService.setAnalytics("Dashboard");
    window.scrollTo(0, 0);



  }

  refresh(): void {
    window.location.reload();
  }

  showare(): void {
    const newWindow = window.open("", "_blank");

    if (newWindow) {
      this.httpService.post("showare", `showare/login`).subscribe(
        (response) => {
          newWindow.location.href = response.url;
        },
        () => {
          newWindow.close();
        }
      );
    } else {
      // Handle the case where the window could not be opened
      console.error("Pop-up blocked");
    }
  }


}
