export const environment = {
  appVersion: '0.0.1',
  httpTimeout: 20000,
  production: true,
  //apiUrl: 'https://api.paulistano.org.br/api/',
  apiUrl: 'https://homolog.meuprojetoweb.com.br/api-cap/current/public/api/',
  //staticUrl: 'https://manager.paulistano.org.br/',
  staticUrl: 'https://homolog.meuprojetoweb.com.br/admin-cap/current/',
  healthApiUrl: 'https://15.228.200.155:8444/api/',
  healthApiUser: 'parceiro-usuario-recadastramento',
  healthApiPass: '9BygAEvaBxVXLbJ',
  firebase: {
    apiKey: 'AIzaSyASjs4xl0_FyKeSAimL9Ne2iM2hfniU_KI',
    authDomain: 'cap-app-a6276.firebaseapp.com',
    databaseURL: 'https://cap-app-a6276.firebaseio.com',
    projectId: 'cap-app-a6276',
    storageBucket: 'cap-app-a6276.appspot.com',
    messagingSenderId: '462459507218',
    appId: '1:462459507218:web:329f18b617518f953afecb',
    measurementId: 'G-VKZR2FR19B'
  },
  recaptcha: {
    v3SiteKey: '6LcijeAmAAAAANjC5828JfQRA89vsealmohq-SLd',
    v2SiteKey: '6LeAkNsmAAAAAIPrONISVdqrY_bDGvy-OJWKFact'
  },
};
