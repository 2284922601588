import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from "src/environments/environment";
import { UtilService } from "src/app/services/util.service";
import { SecureStorage } from "src/app/utils/secure-storage";

@Component({
	selector: 'app-renewal',
	templateUrl: './renewal.component.html',
	styleUrls: ['./renewal.component.css']
})
export class RenewalComponent implements OnInit {

	possuiDependentes: boolean = true;

	private dataUser: any;

	constructor(
		private domSanitizer: DomSanitizer
		) {}

	ngOnInit() {
		window.scrollTo(0, 0);

		this.dataUser = JSON.parse(SecureStorage.getItem('dataUser'));
	}

	getResourceForm(): SafeUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl+"recadastramento/busca/"+this.dataUser.usu_id_pessoa);
	}

}
