import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { SecureStorage } from "src/app/utils/secure-storage";

@Component({
  selector: 'app-health-info',
  templateUrl: './health-info.component.html',
  styleUrls: ['./health-info.component.css']
})
export class HealthInfoComponent implements OnInit {

  possuiDependentes: boolean = true;

  private dataUser: any;

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.dataUser = JSON.parse(SecureStorage.getItem('dataUser'));
  }

  getResourceForm(): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + "dados-medicos/busca/" + this.dataUser.usu_id_pessoa);
  }

}
