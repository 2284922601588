import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { SecureStorage } from 'src/app/utils/secure-storage';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.css'],
})
export class ConfigsComponent implements OnInit {
  public dataUser: any;
  public infos: Array<any> = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
  ) {
    this.dataUser = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.dataUser = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);

    this.infos = [];
    this.loadInfos();
  }

  loadInfos() {
    this.httpService.get('getInfoUser', 'usuarios')
      .subscribe(response => {
        SecureStorage.setItem('dataUser', response);
        this.httpService.get('configuracoes', 'categoria-notificacoes')
          .subscribe(response => {
            this.infos = response;
          }, err => {
            alert('Não foi possível realizar a sua solicitação.');
          });
      }, err => {
        alert('Não foi possível realizar o login.');
      });
  }

  stateChange(event, value) {
    if (event.currentTarget.checked) {
      this.setStatusCategory(value)
    } else {
      this.delStatusCategory(value);
    }
  }

  setStatusPush(event) {
    let data;
    if (event.currentTarget.checked) {
      data = {
        usu_notificacao: 1
      }
    } else {
      data = {
        usu_notificacao: 0
      }
    }

    this.httpService.put('update', 'usuarios/' + this.dataUser.usu_id, data)
      .subscribe(response => {
        this.loadInfos();
      }, err => {
        alert('Não foi possível realizar a alteração.');
      });
  }

  setStatusCategory(categoryId) {
    let data = {
      usrcat_cat_id: categoryId
    }
    this.httpService.post('configuracoes', 'categoria-notificacoes', data)
      .subscribe(response => {
        this.loadInfos();
      }, err => {
        alert('Não foi possível realizar a alteração.');
      });
  }

  delStatusCategory(categoryId) {
    this.httpService.delete('configuracoes', 'categoria-notificacoes/' + categoryId)
      .subscribe(response => {
        this.loadInfos();
      }, err => {
        alert('Não foi possível realizar a alteração.');
      });
  }

}
