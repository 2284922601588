import { Component, OnInit, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SecureStorage } from 'src/app/utils/secure-storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public dataUser: any;
  public notificationsNotRead = 0;
  constructor(
    public httpService: HttpService
  ) {
    this.dataUser = JSON.parse(SecureStorage.getItem('dataUser'));
  }

  ngOnInit() {
    this.dataUser = JSON.parse(SecureStorage.getItem('dataUser'));
    this.httpService.get('inicial', 'notificacoes/total-nao-lidas')
      .subscribe(response => {
        this.notificationsNotRead = response.totalNotRead;
      }, err => {
      });
  }



}
