import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SecureStorage } from 'src/app/utils/secure-storage';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})

export class UpdateComponent implements OnInit {
  public form: FormGroup;
  public dataUser: any;
  public isAlter: boolean = false;
  public userId;

  public isActiveToggleTextPassword: Boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);


    this.form = this.formBuilder.group({
      'usu_id_pessoa': [null, [Validators.required]],
      'usu_nome': [null, [Validators.required]],
      'usu_cpf': [null, []],
      'usu_titulo': [null, [Validators.required]],
      'usu_login': [null, [Validators.required]],
      'usu_senha': [null, []],
      'usu_senha_confirmation': [null, []],
      'usu_email': [null, [Validators.required]],
      'usu_dt_nascimento': [null, [Validators.required]],
      'usu_dt_exame': [null, []],
      'usu_status': [1, [Validators.required]],
    });

    let id = localStorage.getItem("coduser");

    this.httpService.get('getInfoUser', 'usuarios/myaccount')
      .subscribe(response => {
        SecureStorage.setItem('dataUser', response);
        this.dataUser = response;

        this.setValuesToForm();
      }, err => {
        alert('Não foi possível realizar o login.');
      });
  }

  alterPass() {
    this.isAlter = true;
  }


  setValuesToForm() {
    this.userId = this.dataUser.usu_id;
    this.form.controls['usu_id_pessoa'].setValue(this.dataUser.usu_id_pessoa);
    this.form.controls['usu_nome'].setValue(this.dataUser.usu_nome);
    this.form.controls['usu_cpf'].setValue(this.dataUser.usu_cpf);
    this.form.controls['usu_titulo'].setValue(this.dataUser.usu_titulo);
    this.form.controls['usu_login'].setValue(this.dataUser.usu_login);
    this.form.controls['usu_email'].setValue(this.dataUser.usu_email);

    this.form.controls['usu_dt_nascimento'].setValue(this.formatDate(this.dataUser.usu_dt_nascimento, 'br'));

    if(this.dataUser.usu_dt_exame != null) {
      this.form.controls['usu_dt_exame'].setValue(this.dataUser.usu_dt_exame.charAt(8) + this.dataUser.usu_dt_exame.charAt(9) + '/' + this.dataUser.usu_dt_exame.charAt(5) + this.dataUser.usu_dt_exame.charAt(6) + '/' + this.dataUser.usu_dt_exame.charAt(0) + this.dataUser.usu_dt_exame.charAt(1) + this.dataUser.usu_dt_exame.charAt(2) + this.dataUser.usu_dt_exame.charAt(3));
    }else{
      this.form.controls['usu_dt_exame'].setValue('Nenhum exame encontrado');
    }

    this.form.controls['usu_senha'].setValue(this.dataUser.usu_senha);
  }

  private formatDate(date, type) {
    return date.charAt(8) + date.charAt(9) + '/' + date.charAt(5) + date.charAt(6) + '/' + date.charAt(0) + date.charAt(1) + date.charAt(2) + date.charAt(3);
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    if (type == 'br') {
      return diaF + "-" + mesF + "-" + anoF;
    } else {
      return anoF + '-' + mesF + '-' + diaF;
    }
  }

  public toggleTextPassword(): void {
    this.isActiveToggleTextPassword = this.isActiveToggleTextPassword == true ? false : true;
  }

  public getType() {
    return this.isActiveToggleTextPassword ? "password" : "text";
  }

  onFormSubmit(form) {

    let data;

    if (this.form.value.usu_email != this.dataUser.usu_email || this.form.value.usu_login != this.dataUser.usu_login) {
      if (this.form.value.usu_senha == null) {
        data = {
          usu_login: this.form.value.usu_login,
          usu_email: this.form.value.usu_email
        }
      } else {
        data = {
          usu_login: this.form.value.usu_login,
          usu_email: this.form.value.usu_email,
          usu_senha: this.form.value.usu_senha,
          usu_senha_confirmation: this.form.value.usu_senha_confirmation
        }
      }
    } else {
      data = {
        usu_senha: this.form.value.usu_senha,
        usu_senha_confirmation: this.form.value.usu_senha_confirmation
      }
    }


    this.httpService.put('usuarios/'+this.userId, 'usuarios/'+this.userId, data)
      .subscribe(response => {
        this.loaderService.openModalAlert('Sucesso!', 'Cadastro atualizado.', 'success');
      }, err => {
      });
  }

  loadUser() {
        this.httpService.get('getInfoUser', 'usuarios/myaccount')
            .subscribe(response => {
                SecureStorage.setItem('dataUser', response);
                this.dataUser = response;

            }, err => {
                alert('Não foi possível realizar o login.');
            });
    }

}
