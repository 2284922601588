import * as CryptoJS from 'crypto-js';

export class SecureStorage {
  private static secretKey = 'E#4K&9zY@pT!qWfA*1LgX8%$kN^2HdCm';

  static setItem(key: string, value: any): void {
    const jsonValue = JSON.stringify(value);
    const encryptedValue = CryptoJS.AES.encrypt(jsonValue, this.secretKey).toString();
    localStorage.setItem(key, encryptedValue);
  }

  static getItem<T>(key: string): T | null {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;

    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue;
    } catch (e) {
      console.error('Erro ao descriptografar os dados:', e);
      return null;
    }
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  static clear(): void {
    localStorage.clear();
  }
}
