import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { SecureStorage } from 'src/app/utils/secure-storage';

@Component({
  selector: 'app-privacy-polity',
  templateUrl: './privacy-polity.component.html',
  styleUrls: ['./privacy-polity.component.css'],
})
export class PrivacyPolityComponent implements OnInit {
  public dataUser:any;
  public pageInfo: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
  ) {
    this.dataUser  = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.dataUser  = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
    this.loadInfos();
  }


  loadInfos() {
    this.httpService.get('privacy-polity', 'textos/9')
      .subscribe(response => {
        this.pageInfo = response;
      }, err => {
        alert('Não foi possível realizar a sua solicitação.');
      });
  }
}
