import { Component, OnInit, Input, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { HttpService } from "src/app/services/http.service";
import { LoaderService } from "src/app/services/loader.service";
import { DOCUMENT } from "@angular/common";
import { UtilService } from "src/app/services/util.service";
import { SecureStorage } from "src/app/utils/secure-storage";
declare var $: any;
@Component({
  selector: "app-modal-send-mail",
  templateUrl: "./modal-send-mail.component.html",
  styleUrls: ["./modal-send-mail.component.css"],
})
export class ModalSendMailComponent implements OnInit {
  public button: any;
  public loading: any;
  public password_type: string = "password";
  public form: FormGroup;
  public dataUser: any;

  @Input() slipID;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    @Inject(DOCUMENT) document,
    public loaderService: LoaderService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.utilService.setAnalytics("Boletos (Enviar e-mail)");
    this.form = this.formBuilder.group({
      email: [null, [Validators.required]],
    });
    this.dataUser = SecureStorage.getItem('dataUser');
    this.dataUser = JSON.parse(this.dataUser);
  }

  showModal(): void {
    $("#modalMail").modal("show");
  }

  sendModal(): void {
    //do something here
    this.hideModal();
  }
  hideModal(): void {
    document.getElementById("close-modal").click();
  }

  sendMail() {
    let tipo = (<HTMLInputElement>document.querySelector('input[name="tipo_email"]:checked')).value;

    let dataRequest = {
      cdBoleto: this.slipID,
      tipo: tipo,
    };

    this.httpService
      .post(
        "boletos/" + this.slipID + "/email",
        "boletos/" + this.slipID + "/email/"+tipo,
        dataRequest
      )
      .subscribe(
        (response) => {
          this.loaderService.openModalAlert(
              "Sucesso",
              "E-mail enviado com sucesso.",
              "success"
            );
          this.activeModal.close();
        },
        (error) => {
          //this.activeModal.close();
          this.loaderService.openModalAlert(
            "Ops",
            "Não foi possível enviar o e-mail.",
            "error"
          );
        }
      );
  }
}
