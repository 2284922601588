import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import * as CryptoJS from "crypto-js";
import { LoaderService } from "src/app/services/loader.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from 'src/app/services/util.service';
import { SecureStorage } from "src/app/utils/secure-storage";

@Component({
    selector: "app-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.css"],
})
export class ListComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number; month: number };

    private loadingBankSlips: boolean = false;
    public dataUser: any;
    public dataReturned: any;
    public loading: any;
    public form: FormGroup;
    public slips: Array<any> = [];
    public months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
    ];

    public dateFilterIni;
    public dateFilterFim;
    public dateOriginal;

    public selectedSlip: any;

    public wasClicked = false;

    constructor(
        private calendar: NgbCalendar,
        private formBuilder: FormBuilder,
        private httpService: HttpService,
        private utilService: UtilService,
        public loaderService: LoaderService
    ) {
        this.dataUser = SecureStorage.getItem('dataUser');
        this.dataUser = JSON.parse(this.dataUser);
    }

    ngOnInit() {
        this.utilService.setAnalytics("Boletos (Histórico)");
        localStorage.removeItem("localSlipsVigenteDetails");
        window.scrollTo(0, 0);
        this.dataUser = SecureStorage.getItem('dataUser');
        this.dataUser = JSON.parse(this.dataUser);

        this.form = this.formBuilder.group({
            data_ini: [null, [Validators.required]],
            data_fim: [null, [Validators.required]],
        });

        this.slips = [];

        if (localStorage.getItem("localSlipsVigente")) {
            let localSlipsVigente: any = localStorage.getItem("localSlipsVigente");
            this.slips = JSON.parse(localSlipsVigente);
        } else {
            this.loadSlips();
        }
    }

    selectToday() {
        this.model = this.calendar.getToday();
    }

    onDateSelect(event) {
        this.dateOriginal = event.day + "/" + event.month + "" + event.year;
        //this.form.get('usu_dt_nascimento').setValue(event.year+'-'+event.month+'-'+event.day);
        return;
    }

    updateDate(event) {
        this.form
            .get("data_ini")
            .setValue(event.year + "-" + event.month + "-" + event.day);
        return;
    }

    public encryptData(data) {
        try {
            return CryptoJS.AES.encrypt(
                JSON.stringify(data),
                "passwordCAP"
            ).toString();
        } catch (e) {
            console.log(e);
        }
    }

    loadSlips() {
        var year = (new Date().getFullYear());
        var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
        var dia = (new Date().getDate());
        var dia_fim = new Date(year, (new Date().getMonth() + 2), 0);

        var d = new Date();
        //console.log("meu teste");
        d.setMonth(2);
        console.log(d);

        let url =
            "boletos?id_pessoa=" + this.dataUser.usu_id_pessoa + "&data_ini=01/" + month + "/" + (year) + "&expired=false";

        this.loadingBankSlips = true;

        this.httpService
            .get("getBoletos", url)
            //this.authService.getBoletos(this.dataUser.usu_id_pessoa, '01/01/2019', '19/09/2020')
            .subscribe(
                (response) => {
                    this.loadingBankSlips = false;
                    localStorage.setItem("localSlipsVigente", JSON.stringify(response));
                    this.slips = response;
                },
                (err) => {
                    this.loadingBankSlips = false;
                    alert("Não foi possível realizar a sua solicitação.");
                }
            );
    }

    setSlip(slip) {
        localStorage.setItem("slipInfo", JSON.stringify(slip));
        localStorage.setItem("slipOriginal", JSON.stringify(slip));
    }

    copiarCodigoBarras(slip) {
        if (slip.is_expired) {
            this.loaderService.openModalAlert(
                "Boleto não encontrado!",
                "Para obter este boleto, por favor enviar um e-mail para: tesouraria@paulistano.org.br",
                "error"
            );
        } else {
            if (slip.nr_digital != undefined) {
                var copyText = slip.nr_digital;
                navigator.clipboard.writeText(copyText);
                this.loaderService.openModalAlert(
                    "",
                    "Linha digitável copiada para área de transferência!",
                    "success"
                );
            } else {
                this.loaderService.openModalAlert(
                    "Ops",
                    "Boleto cadastrado em débito automático.",
                    "error"
                );
            }
        }
        return false;
    }

    filterDate() {
        if (this.form.value.data_ini != null && this.form.value.data_fim != null) {
            this.slips = [];
            let url =
                "boletos?id_pessoa=" +
                this.dataUser.usu_id_pessoa +
                "&data_ini=" +
                this.formtDateToPtBr(this.form.value.data_ini) +
                "&data_fim=" +
                this.formtDateToPtBr(this.form.value.data_fim);
            this.httpService.get("getBoletosFilter", url).subscribe(
                (response) => {
                    this.slips = response;
                },
                (err) => {
                    alert("Não foi possível realizar o login.");
                }
            );
        }
    }

    formatInfoPayment(info) {
        if (info == "S") {
            return "À pagar";
        } else {
            return "Pago";
        }
    }

    openModalPDF(slip) {
        if (slip.is_expired) {
            this.loaderService.openModalAlert(
                "Boleto não encontrado!",
                "Para obter este boleto, por favor enviar um e-mail para: tesouraria@paulistano.org.br",
                "error"
            );
        } else {
            this.loaderService.openModalAlert(
                "Ops",
                "Boleto não encontrado no sistema.",
                "error"
            );
        }
        return false;
    }

    openModalSendMail(slip) {
        if (slip.is_expired) {
            this.loaderService.openModalAlert(
                "Boleto não encontrado!",
                "Para obter este boleto, por favor enviar um e-mail para: tesouraria@paulistano.org.br",
                "error"
            );
        } else {
            if (slip.cd_boleto == null || slip.cd_boleto == "") {
                this.loaderService.openModalAlert(
                    "Ops",
                    "Boleto não encontrado no sistema.",
                    "error"
                );
                return false;
            } else {
                /*this.httpService
                .post("boleto/email", "boletos/"+cd_boleto+"/email")
                .subscribe(
                  (response) => {
                    this.loaderService.openModalAlert('Sucesso', 'E-mail enviado com sucesso', 'success');
                  },
                  (error) => {}
                );*/
                this.loaderService.openModalSendMail(slip.cd_boleto);
            }
        }
    }

    formtDateToPtBr(date) {
        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = dia.length == 1 ? "0" + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = mes.length == 1 ? "0" + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }

    formtDateToPtBrMes(date) {
        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = dia.length == 1 ? "0" + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = mes.length == 1 ? "0" + mes : mes,
            anoF = data.getFullYear();
        return this.months[data.getMonth()] + "/" + anoF;
    }

    onFormSubmit(form: NgForm) {
        this.loadingBankSlips = true;

        let url = "boletos?id_pessoa=37742&data_ini=01/01/2019&data_fim=19/09/2020";
        this.httpService.get("getBoletos", url).subscribe(
            (response) => {
                this.loadingBankSlips = false;
                this.slips = response;
            },
            (err) => {
                this.loadingBankSlips = false;
                alert("Não foi possível carregar as informações.");
            }
        );
    }

    isLoadingBoletos(): boolean {
        return this.loadingBankSlips;
    }

    onSelect(slip): void {
        if (slip != this.selectedSlip) {
            this.selectedSlip = slip;
        } else {
            this.selectedSlip = false;
        }
    }

    loadUser() {
        this.httpService.get('getInfoUser', 'usuarios/myaccount')
            .subscribe(response => {
                SecureStorage.setItem('dataUser', response);
                this.dataUser = response;

            }, err => {
                alert('Não foi possível realizar o login.');
            });
    }

}
